import React from 'react';
import styled from 'styled-components';
import { brandColours, fontSize } from '../styles';
import { Container } from './ui';

const StyledHighlightedNotice = styled.div`
  margin: 20px 0;
`;

const StyledInner = styled.div`
  position: relative;
  padding: 15px 15px 15px 40px;
  background-color: ${brandColours.octonary};
  border-radius: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 15px;
    bottom: 10px;
    display: block;
    width: 4px;
    background-color: ${brandColours.septenary};
    border-radius: 3px;
  }
`;

const StyledText = styled.div`
  p {
    ${fontSize(14)};

    a {
      text-decoration: underline;
    }
  }
`;

const HighlightedNotice = ({ text }) =>
  text && (
    <StyledHighlightedNotice>
      <Container>
        <StyledInner>
          <StyledText dangerouslySetInnerHTML={{ __html: text }} />
        </StyledInner>
      </Container>
    </StyledHighlightedNotice>
  );

export default HighlightedNotice;
