import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import HighlightedNotice from '../components/HighlightedNotice';
import ModularBlocks from '../components/ModularBlocks';

const IndexPage = () => {
  const {
    datoCmsHome: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      bannerLink,
      highlightedNotice,
      modularBlocks,
    },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      datoCmsHome {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          ...HomePageBannerImageFragment
        }
        bannerText
        bannerLink {
          text
          page {
            ...LinkFragment
          }
        }
        highlightedNotice
        modularBlocks {
          ...ContentFeaturedLogosModularBlockFragment
          ...ExternalVideoModularBlockFragment
          ...FeaturedActivitiesModularBlockFragment
          ...FeaturedLogosModularBlockFragment
          ...ImageCtaModularBlockFragment
          ...ImageCardsModularBlockFragment
          ...ImagesContentModularBlockFragment
          ...InternalVideoModularBlockFragment
          ...LatestBlogPostsModularBlockFragment
          ...TestimonialsModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          heading={title}
          image={bannerImage}
          text={bannerText}
          link={bannerLink}
          isHomepage={true}
        />
        <HighlightedNotice text={highlightedNotice} />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default IndexPage;
